<template>
    <div class="  top-shadow flex-row justify-between">
      <div class="image-text_5 flex-row justify-between">
        <div class="group_4 flex-col">
          <div class="box_2 flex-col">
            <div class="section_3 flex-col">
              <div class="box_3 flex-col">
                <div class="block_4 flex-col"></div>
                <div class="text-wrapper_2 flex-col"><span class="text_2">$</span></div>
              </div>
            </div>
          </div>
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngd98c63ba920ab080458c2d89d5634cf7a2263e27fd196798e1fda322b6c1eb5f.png"
          />
          <div class="box_4 flex-col justify-center">
            <div class="image-wrapper_1 flex-col">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngb22bdd9361e7741cd1f069c172afb701077a26031742b5deb7754ead433c3241.png"
              />
              <img
                class="thumbnail_2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngfbf887a8740514f9ac5c5749a1dc8e8a442f48699ea2f90144057ddacf0267c5.png"
              />
            </div>
          </div>
          <div class="box_5 flex-col"><div class="group_5 flex-col"></div></div>
          <div class="text-wrapper_3 flex-col"><span class="text_3">LoanMXN</span></div>
        </div>
        <div class="text-group_4 flex-col justify-between">
          <span class="text_4">LoanMXN</span>
          <span class="text_5">
            En&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;lugar,&nbsp;aquí&nbsp;estamos&nbsp;cuando&nbsp;nos&nbsp;necesite
          </span>
        </div>
      </div>
      <div class="section_4 flex-row">
        <div class="image-text_6 flex-row justify-between">
          <img
            class="thumbnail_3"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPnga212f0d291518241c85f5c229f536f65f947205aaee4fd1c68f7183fd8e5d873.png"
          />
          <span class="text-group_2">descargar</span>
        </div>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

 <style scoped lang="css" src="./assets/index.response.css" />