<template>
  <div class="page flex-col">
    <elg-header></elg-header>
    <elg-footer></elg-footer>
  </div>
</template>
<script>

import ElgFooter from "./elg-footer.vue";
import ElgHeader from "./elg-header.vue";
export default {
  components: {
    ElgFooter,ElgHeader
  },
  data() {
    return {
      content: [
        { id: 1, text: '这里是页面的其他内容，可以滚动。' },
        { id: 2, text: '这里是页面的其他内容，可以滚动。' },
        // 更多内容...
      ],
    };
  },
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
 