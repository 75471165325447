<template>
  <div>
        <div class="group_6 flex-col">
      <div class="block_18 flex-row justify-between">
        <div class="block_6 flex-col"></div>
        <div class="block_7 flex-col"></div>
      </div>
      <div class="block_19 flex-row justify-between">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng9a571e5308eff7a4ab8f1b20ec6ba35e0127c6a6a4d000b09d2a98e383b01c82.png"
        />
        <div class="image-wrapper_10 flex-col justify-between">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng59898cc9e14441fa50dfbccf6bec3361bb2ed341d0c46b1ba5348539ff414935.png"
          />
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng321ab92fd20c6dc257288dac925799b63750458e87ad2a5b37164057d07a0c00.png"
          />
        </div>
      </div>
      <div class="block_20 flex-row">
        <div class="text-wrapper_4">
          <span class="text_6">Fácil&nbsp;de&nbsp;operar</span>
          <span class="text_7">/</span>
          <span class="text_8">&nbsp;Límite&nbsp;alto</span>
          <span class="text_9">/</span>
          <span class="text_10">&nbsp;Pago&nbsp;rápido</span>
        </div>
      </div>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng2f227afc7770297235068431507c2604f2960bc07016a1c7c5be687e06df7002.png"
      />
      <div class="block_10 flex-col">
        <div class="box_6 flex-col">
          <div class="image-wrapper_3 flex-col">
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/a7c8556aa29a42e1afa6fe3a1019bf6b_mergeImage.png"
            />
          </div>
        </div>
      </div>
      <span class="paragraph_1">
        Quantum&nbsp;
        <br />
        Lion-LoanMXN
      </span>
      <div class="block_12 flex-col"></div>
      <div class="block_11 flex-row">
        <div class="box_15 flex-col justify-between">
          <div class="text-wrapper_14 flex-row justify-between">
            <span class="text_11">MX$</span>
            <span class="text_12">$20,000</span>
            <span class="text_13">Máximo</span>
          </div>
          <div class="text-wrapper_6">
            <span class="text_14">Interés&nbsp;mínimo&nbsp;de</span>
            <span class="text_15"></span>
            <span class="text_16">0.5%</span>
            <span class="text_17"></span>
            <span class="text_18">/día,&nbsp;plazo&nbsp;máximo&nbsp;de</span>
            <span class="text_19"></span>
            <span class="text_20">365</span>
            <span class="text_21"></span>
            <span class="text_22">días</span>
          </div>
        </div>
        <div class="group_8 flex-col justify-center align-center">
          <div class="box_7 flex-col justify-center align-center">
            <div class="section_5 flex-col justify-center align-center">
              <div class="section_6 flex-col">
                <div class="image-wrapper_11 flex-row">
                  <img
                    class="thumbnail_4"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPngc2ef03455018ca17faf4306d2753672c7d12b10a82a37c2f7a16eb2a1ed6323e.png"
                  />
                </div>
                <div class="image-wrapper_12 flex-row">
                  <img
                    class="thumbnail_5"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng5ad46d3e9b5ab144c8f57039b752f9b7b48c0c76252a030b31a7855ea0114dd7.png"
                  />
                </div>
                <div class="text-wrapper_15 flex-row"><span class="text_23">Descargar&nbsp;la&nbsp;app</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block_13 flex-row">
        <div class="section_7 flex-col">
          <div class="group_9 flex-col">
            <div class="box_8 flex-col">
              <div class="group_10 flex-col">
                <div class="block_14 flex-col"></div>
                <div class="text-wrapper_8 flex-col"><span class="text_24">$</span></div>
              </div>
            </div>
          </div>
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng85b2489f72cd266e742c1f5e4c8a92632388beb85e0efdda9c177ef3102a4649.png"
          />
          <div class="group_11 flex-col justify-center">
            <div class="image-wrapper_6 flex-col">
              <img
                class="thumbnail_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd4498e06521650e7034c6d4ad7d0bf4c66cb9428d9926f1bd9a0c5928edf7f56.png"
              />
            </div>
          </div>
          <div class="group_12 flex-col">
            <div class="block_15 flex-col"><div class="block_16 flex-col"></div></div>
          </div>
          <div class="text-wrapper_9 flex-col"><span class="text_25">LoanMXN</span></div>
        </div>
        <span class="text_26">Quantum&nbsp;Lion-LoanMXN</span>
        <div class="box_16 flex-col justify-between">
          <span class="text_27">Sobre&nbsp;Nosotros</span>
          <div class="section_9 flex-col"></div>
        </div>
        <span class="text_28">Contáctanos</span>
        <span class="text_29">Política&nbsp;de&nbsp;Privacidad</span>
        <span class="text_30">Eliminar&nbsp;Cuenta</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="css" src="./assets/index.response.css" />