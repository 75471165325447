<template>
    <div>
        <div id="main-content">
            <yi-pin-shou-ye />
        </div>
        <div ref="footerRef" class="footer">
            <elg-bottom />
        </div>
    </div>
</template>
<script>
import YiPinShouYe from './yipinshouye.vue'
import ElgBottom from '../guanwangshouyeyipingxuanfu/elg-bottom.vue'
export default {
    components: { ElgBottom, YiPinShouYe }
}
</script>
 <style scoped lang="css" src="./assets/index.response.css" />