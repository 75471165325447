<template>
  <div>
        <div class="box_12 flex-col">
      <div class="box_13 flex-row"><div class="group_2 flex-col"></div></div>
      <div class="box_14 flex-row justify-between">
        <div class="block_3 flex-col">
          <div class="box_1 flex-col"><div class="section_2 flex-col"></div></div>
        </div>
        <div class="section_1 flex-col"></div>
      </div>
      <div class="text-wrapper_13 flex-row">
        <span class="text_1">Quantum&nbsp;Lion,&nbsp;S.A.P.I.&nbsp;de&nbsp;C.V.,&nbsp;SOFOM,&nbsp;E.N.R.</span>
      </div>
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng19406944be13f47bf7f8b58c26507d3e0dccac0a8150a7e4e7445d66e3c09d31.png"
      />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

 <style scoped lang="css" src="./assets/index.response.css" />