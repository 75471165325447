<template>
    <div class="page flex-col">
        <div class="group_1 flex-row">
            <div class="group_2 flex-col">
                <div class="group_3 flex-row">
                    <img
                        class="image_1"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng626cf57f4dd24ea76055837de7ceccbde3291696dd429536254e05a0c98a6a55.png"
                    />
                    <div class="text-wrapper_1">
                        <span class="text_1">《Política&nbsp;de&nbsp;</span>
                        <span class="text_2">Privacidad</span>
                        <span class="text_3">》</span>
                    </div>
                    <div class="section_1 flex-col"></div>
                    <div class="text-wrapper_2">
                        <span class="paragraph_1">
                            loanmxn&nbsp;(en&nbsp;adelante,&nbsp;"nosotros")&nbsp;valora&nbsp;profundamente&nbsp;la&nbsp;protección&nbsp;de&nbsp;la&nbsp;información&nbsp;personal&nbsp;de&nbsp;los&nbsp;usuarios&nbsp;y&nbsp;se&nbsp;compromete&nbsp;a&nbsp;ofrecer&nbsp;servicios&nbsp;seguros&nbsp;y&nbsp;confiables.&nbsp;Esta&nbsp;política&nbsp;de&nbsp;privacidad&nbsp;tiene&nbsp;como&nbsp;objetivo&nbsp;explicar&nbsp;cómo&nbsp;recopilamos,&nbsp;usamos,&nbsp;almacenamos&nbsp;y&nbsp;protegemos&nbsp;su&nbsp;información&nbsp;personal.&nbsp;Esperamos&nbsp;que&nbsp;esta&nbsp;política&nbsp;le&nbsp;brinde&nbsp;claridad&nbsp;sobre&nbsp;cómo&nbsp;manejamos&nbsp;su&nbsp;información.
                            <br />
                        </span>
                        <span class="paragraph_2">
                            Elementos&nbsp;y&nbsp;Métodos&nbsp;de&nbsp;Recolección&nbsp;de&nbsp;Información&nbsp;Personal
                            <br />
                        </span>
                        <span class="text_4">
                            loanmxn&nbsp;ofrece&nbsp;una&nbsp;evaluación&nbsp;crediticia&nbsp;integral&nbsp;y&nbsp;servicios&nbsp;crediticios&nbsp;mejorados,&nbsp;utilizando&nbsp;tecnología&nbsp;de&nbsp;transmisión&nbsp;de&nbsp;datos&nbsp;cifrada.&nbsp;La&nbsp;información&nbsp;recopilada&nbsp;se&nbsp;sube&nbsp;a&nbsp;www.quantumlion.mx&nbsp;para&nbsp;almacenamiento&nbsp;cifrado,&nbsp;protegiendo&nbsp;así&nbsp;la&nbsp;seguridad&nbsp;de&nbsp;la&nbsp;información&nbsp;del&nbsp;usuario.&nbsp;No&nbsp;recopilamos&nbsp;sus&nbsp;datos&nbsp;personales&nbsp;sin&nbsp;su&nbsp;consentimiento&nbsp;previo
                        </span>
                        <span class="text_5">.</span>
                    </div>
                </div>
                <div class="image-text_1 flex-row justify-between">
                    <div class="text-group_1 flex-col">
                        <span class="text_6"
                            >La&nbsp;información&nbsp;que&nbsp;recopilamos&nbsp;incluye:</span
                        >
                        <span class="text_7">
                            -&nbsp;&nbsp;Permisos&nbsp;de&nbsp;SMS:&nbsp;Al&nbsp;solicitar&nbsp;un&nbsp;préstamo,&nbsp;necesitamos&nbsp;acceder&nbsp;a&nbsp;la&nbsp;información&nbsp;de&nbsp;SMS&nbsp;del&nbsp;usuario,&nbsp;como&nbsp;el&nbsp;título,&nbsp;el&nbsp;contenido&nbsp;y&nbsp;el&nbsp;estado&nbsp;de&nbsp;lectura,&nbsp;para&nbsp;garantizar&nbsp;la&nbsp;seguridad&nbsp;de&nbsp;los&nbsp;fondos,&nbsp;evaluar&nbsp;el&nbsp;crédito&nbsp;del&nbsp;usuario&nbsp;y&nbsp;prevenir&nbsp;fraudes.&nbsp;Esta&nbsp;información&nbsp;se&nbsp;sube&nbsp;a&nbsp;www.quantumlion.mx&nbsp;y&nbsp;no&nbsp;se&nbsp;comparte&nbsp;con&nbsp;terceros.
                        </span>
                        <span class="text_8">
                            -&nbsp;&nbsp;Ubicación&nbsp;Aproximada:&nbsp;Recopilamos&nbsp;y&nbsp;detectamos&nbsp;la&nbsp;ubicación&nbsp;aproximada&nbsp;de&nbsp;su&nbsp;dispositivo&nbsp;para&nbsp;proporcionar&nbsp;servicios&nbsp;relacionados&nbsp;con&nbsp;la&nbsp;solicitud&nbsp;de&nbsp;préstamos,&nbsp;reducir&nbsp;riesgos&nbsp;y&nbsp;ofrecer&nbsp;ofertas&nbsp;de&nbsp;préstamos&nbsp;personalizadas.&nbsp;Esto&nbsp;también&nbsp;ayuda&nbsp;a&nbsp;verificar&nbsp;su&nbsp;dirección&nbsp;y&nbsp;tomar&nbsp;mejores&nbsp;decisiones&nbsp;de&nbsp;riesgo&nbsp;crediticio,&nbsp;acelerando&nbsp;el&nbsp;proceso&nbsp;de&nbsp;préstamo.
                        </span>
                        <span class="text_9">
                            -&nbsp;&nbsp;Información&nbsp;del&nbsp;Dispositivo:&nbsp;Recopilamos&nbsp;información&nbsp;sobre&nbsp;el&nbsp;hardware&nbsp;y&nbsp;software,&nbsp;como&nbsp;modelo&nbsp;del&nbsp;hardware,&nbsp;versión&nbsp;del&nbsp;sistema&nbsp;operativo,&nbsp;memoria&nbsp;del&nbsp;dispositivo,&nbsp;identificadores&nbsp;publicitarios,&nbsp;identificadores&nbsp;únicos&nbsp;de&nbsp;aplicaciones,&nbsp;aplicaciones&nbsp;instaladas,&nbsp;identificadores&nbsp;únicos&nbsp;del&nbsp;dispositivo&nbsp;y&nbsp;datos&nbsp;de&nbsp;uso&nbsp;del&nbsp;dispositivo,&nbsp;para&nbsp;ofrecer&nbsp;un&nbsp;mejor&nbsp;servicio.
                        </span>
                        <span class="text_10">
                            -&nbsp;&nbsp;Información&nbsp;Personal&nbsp;del&nbsp;Usuario:&nbsp;Para&nbsp;el&nbsp;control&nbsp;de&nbsp;riesgos&nbsp;y&nbsp;la&nbsp;evaluación&nbsp;de&nbsp;la&nbsp;elegibilidad,&nbsp;necesitamos&nbsp;información&nbsp;personal&nbsp;como&nbsp;nombre&nbsp;completo,&nbsp;estado&nbsp;civil,&nbsp;género,&nbsp;fecha&nbsp;de&nbsp;nacimiento,&nbsp;edad,&nbsp;país,&nbsp;documento&nbsp;oficial&nbsp;de&nbsp;identidad,&nbsp;número&nbsp;de&nbsp;registro&nbsp;único&nbsp;(CURP),&nbsp;correo&nbsp;electrónico&nbsp;y&nbsp;dirección&nbsp;de&nbsp;residencia.&nbsp;Realizamos&nbsp;depósitos&nbsp;bancarios&nbsp;directos&nbsp;mediante&nbsp;las&nbsp;cuentas&nbsp;bancarias&nbsp;y/o&nbsp;números&nbsp;de&nbsp;tarjeta&nbsp;proporcionados&nbsp;por&nbsp;el&nbsp;usuario&nbsp;y&nbsp;códigos&nbsp;bancarios&nbsp;estandarizados&nbsp;(CLABE).
                        </span>
                        <span class="text_11">
                            -&nbsp;&nbsp;Información&nbsp;de&nbsp;Contacto:&nbsp;Al&nbsp;solicitar&nbsp;un&nbsp;préstamo&nbsp;en&nbsp;loanmxn,&nbsp;el&nbsp;usuario&nbsp;debe&nbsp;proporcionar&nbsp;información&nbsp;de&nbsp;contacto&nbsp;de&nbsp;una&nbsp;persona&nbsp;de&nbsp;emergencia&nbsp;(número&nbsp;de&nbsp;contacto,&nbsp;nombre&nbsp;del&nbsp;contacto,&nbsp;relación)&nbsp;para&nbsp;verificar&nbsp;la&nbsp;autenticidad&nbsp;del&nbsp;prestatario&nbsp;y&nbsp;evaluar&nbsp;su&nbsp;crédito,&nbsp;así&nbsp;como&nbsp;realizar&nbsp;análisis&nbsp;antifraude.&nbsp;La&nbsp;información&nbsp;de&nbsp;contacto&nbsp;se&nbsp;sube&nbsp;a&nbsp;www.quantumlion.mx&nbsp;para&nbsp;almacenamiento&nbsp;cifrado&nbsp;y&nbsp;no&nbsp;se&nbsp;revela&nbsp;a&nbsp;terceros&nbsp;sin&nbsp;permiso.
                        </span>
                        <span class="text_12">
                            -&nbsp;&nbsp;Permisos&nbsp;de&nbsp;Cámara:&nbsp;Algunas&nbsp;funciones&nbsp;de&nbsp;nuestra&nbsp;aplicación&nbsp;pueden&nbsp;utilizar&nbsp;reconocimiento&nbsp;facial&nbsp;y&nbsp;carga&nbsp;de&nbsp;imágenes,&nbsp;por&nbsp;lo&nbsp;que&nbsp;se&nbsp;requiere&nbsp;el&nbsp;permiso&nbsp;de&nbsp;acceso&nbsp;a&nbsp;la&nbsp;cámara.
                        </span>
                    </div>
                    <img
                        class="image_2"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng05e2743a731de340389fc912f855c2fc8f6d6c9db78298a0d4f51d135b66e0f0.png"
                    />
                </div>
                <span class="text_13"
                    >Uso&nbsp;de&nbsp;la&nbsp;Información&nbsp;Recopilada：</span
                >
                <div class="group_4 flex-row justify-between">
                    <img
                        class="image_3"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPngbfab5dfbd8f1fb5ee4b620ece55c7743c257441949d623a917c6d0728b0ec554.png"
                    />
                    <div class="text-wrapper_3 flex-col">
                        <span class="paragraph_3">
                            Utilizamos&nbsp;la&nbsp;información&nbsp;recopilada&nbsp;para&nbsp;ofrecer,&nbsp;mantener&nbsp;y&nbsp;mejorar&nbsp;las&nbsp;funciones&nbsp;y&nbsp;servicios&nbsp;de&nbsp;la&nbsp;aplicación.&nbsp;Esta&nbsp;información&nbsp;se&nbsp;usa&nbsp;para&nbsp;personalizar&nbsp;los&nbsp;servicios,&nbsp;recomendar&nbsp;contenido,&nbsp;funciones&nbsp;y&nbsp;promociones&nbsp;basadas&nbsp;en&nbsp;sus&nbsp;intereses.&nbsp;Al&nbsp;analizar&nbsp;la&nbsp;información,&nbsp;entendemos&nbsp;los&nbsp;patrones&nbsp;de&nbsp;comportamiento&nbsp;y&nbsp;uso&nbsp;de&nbsp;los&nbsp;usuarios,&nbsp;optimizando&nbsp;el&nbsp;diseño&nbsp;de&nbsp;la&nbsp;interfaz&nbsp;y&nbsp;la&nbsp;experiencia&nbsp;de&nbsp;usuario&nbsp;de&nbsp;la&nbsp;aplicación.
                            <br />
                            La&nbsp;información&nbsp;también&nbsp;se&nbsp;usa&nbsp;para&nbsp;soporte&nbsp;técnico&nbsp;y&nbsp;servicio&nbsp;al&nbsp;cliente,&nbsp;respondiendo&nbsp;a&nbsp;consultas,&nbsp;resolviendo&nbsp;problemas&nbsp;y&nbsp;ofreciendo&nbsp;asistencia&nbsp;según&nbsp;sus&nbsp;necesidades&nbsp;y&nbsp;comentarios.&nbsp;Esto&nbsp;nos&nbsp;ayuda&nbsp;a&nbsp;entender&nbsp;mejor&nbsp;sus&nbsp;necesidades&nbsp;y&nbsp;proporcionar&nbsp;asistencia&nbsp;y&nbsp;recomendaciones&nbsp;específicas.
                        </span>
                        <span class="text_14">
                            Propósitos&nbsp;de&nbsp;la&nbsp;Recolección&nbsp;de&nbsp;Datos&nbsp;Personales：
                        </span>
                        <span class="paragraph_4">
                            1.Crear&nbsp;y&nbsp;gestionar&nbsp;cuentas&nbsp;de&nbsp;usuario&nbsp;para&nbsp;acceder&nbsp;a&nbsp;las&nbsp;funciones&nbsp;y&nbsp;servicios&nbsp;de&nbsp;la&nbsp;aplicación.
                            <br />
                            <br />
                            2.Asistir&nbsp;en&nbsp;la&nbsp;evaluación&nbsp;crediticia&nbsp;para&nbsp;conocer&nbsp;la&nbsp;situación&nbsp;crediticia&nbsp;y&nbsp;capacidad&nbsp;de&nbsp;pago&nbsp;del&nbsp;usuario.
                            <br />
                            <br />
                            3.Proporcionar&nbsp;servicios&nbsp;personalizados&nbsp;y&nbsp;recomendaciones&nbsp;para&nbsp;satisfacer&nbsp;sus&nbsp;necesidades&nbsp;y&nbsp;preferencias.
                            <br />
                            <br />
                            4.Ofrecer&nbsp;servicios&nbsp;y&nbsp;productos&nbsp;personalizados,&nbsp;optimizar&nbsp;el&nbsp;diseño&nbsp;de&nbsp;funciones&nbsp;y&nbsp;mejorar&nbsp;la&nbsp;experiencia&nbsp;del&nbsp;usuario,&nbsp;brindando&nbsp;servicios&nbsp;más&nbsp;eficientes&nbsp;y&nbsp;convenientes.
                            <br />
                            <br />
                            5.Recopilar&nbsp;información&nbsp;de&nbsp;contacto,&nbsp;como&nbsp;números&nbsp;de&nbsp;teléfono&nbsp;y&nbsp;correos&nbsp;electrónicos,&nbsp;para&nbsp;comunicarnos&nbsp;sobre&nbsp;asuntos&nbsp;relacionados,&nbsp;incluyendo&nbsp;el&nbsp;progreso&nbsp;de&nbsp;la&nbsp;aprobación&nbsp;del&nbsp;préstamo,&nbsp;recordatorios&nbsp;de&nbsp;pagos&nbsp;y&nbsp;contratos&nbsp;de&nbsp;préstamo.
                            <br />
                            <br />
                            6.Comprender&nbsp;mejor&nbsp;sus&nbsp;necesidades&nbsp;y&nbsp;comentarios&nbsp;para&nbsp;ofrecer&nbsp;un&nbsp;mejor&nbsp;servicio&nbsp;al&nbsp;cliente&nbsp;y&nbsp;apoyo&nbsp;personalizado.
                        </span>
                        <span class="text_15">
                            Cómo&nbsp;Almacenamos&nbsp;y&nbsp;Protegemos&nbsp;su&nbsp;Información&nbsp;Personal：
                        </span>
                        <span class="paragraph_5">
                            Tomamos&nbsp;medidas&nbsp;técnicas&nbsp;y&nbsp;organizativas&nbsp;razonables&nbsp;para&nbsp;proteger&nbsp;la&nbsp;seguridad&nbsp;y&nbsp;confidencialidad&nbsp;de&nbsp;su&nbsp;información&nbsp;personal,&nbsp;incluyendo&nbsp;cifrado&nbsp;de&nbsp;datos,&nbsp;control&nbsp;de&nbsp;acceso&nbsp;y&nbsp;auditorías&nbsp;de&nbsp;seguridad.&nbsp;Conservamos&nbsp;su&nbsp;información&nbsp;personal&nbsp;solo&nbsp;durante&nbsp;el&nbsp;tiempo&nbsp;necesario&nbsp;para&nbsp;los&nbsp;fines&nbsp;mencionados,&nbsp;a&nbsp;menos&nbsp;que&nbsp;la&nbsp;ley,&nbsp;regulaciones&nbsp;o&nbsp;contratos&nbsp;establezcan&nbsp;lo&nbsp;contrario.&nbsp;Una&nbsp;vez&nbsp;que&nbsp;la&nbsp;información&nbsp;ya&nbsp;no&nbsp;sea&nbsp;necesaria,&nbsp;tomaremos&nbsp;medidas&nbsp;razonables&nbsp;para&nbsp;destruirla&nbsp;de&nbsp;manera&nbsp;segura&nbsp;o&nbsp;anonimizarla.
                            <br />
                            Entendemos&nbsp;que,&nbsp;aunque&nbsp;tomamos&nbsp;medidas&nbsp;razonables&nbsp;para&nbsp;proteger&nbsp;su&nbsp;información,&nbsp;la&nbsp;transmisión&nbsp;y&nbsp;almacenamiento&nbsp;de&nbsp;datos&nbsp;en&nbsp;internet&nbsp;no&nbsp;es&nbsp;absolutamente&nbsp;seguro.&nbsp;No&nbsp;podemos&nbsp;garantizar&nbsp;la&nbsp;seguridad&nbsp;absoluta&nbsp;de&nbsp;la&nbsp;información,&nbsp;y&nbsp;usted&nbsp;debe&nbsp;ser&nbsp;consciente&nbsp;de&nbsp;los&nbsp;riesgos&nbsp;y&nbsp;tomar&nbsp;medidas&nbsp;adecuadas&nbsp;para&nbsp;proteger&nbsp;su&nbsp;información&nbsp;personal.
                        </span>
                    </div>
                </div>
                <div class="group_5 flex-row justify-between">
                    <div class="text-wrapper_4 flex-col justify-between">
                        <span class="text_16">Sus&nbsp;Derechos：</span>
                        <span class="paragraph_6">
                            -&nbsp;&nbsp;Acceso:&nbsp;Usted&nbsp;tiene&nbsp;derecho&nbsp;a&nbsp;acceder&nbsp;gratuitamente&nbsp;a&nbsp;los&nbsp;datos&nbsp;personales&nbsp;básicos&nbsp;y&nbsp;de&nbsp;identificación&nbsp;almacenados.&nbsp;También&nbsp;puede&nbsp;solicitar&nbsp;una&nbsp;copia&nbsp;de&nbsp;su&nbsp;información&nbsp;personal,&nbsp;y&nbsp;en&nbsp;la&nbsp;medida&nbsp;en&nbsp;que&nbsp;la&nbsp;ley&nbsp;lo&nbsp;permita&nbsp;y&nbsp;sea&nbsp;técnicamente&nbsp;viable,&nbsp;le&nbsp;proporcionaremos&nbsp;una&nbsp;copia&nbsp;de&nbsp;su&nbsp;información&nbsp;cuando&nbsp;lo&nbsp;solicite.&nbsp;También&nbsp;puede&nbsp;acceder&nbsp;a&nbsp;su&nbsp;información&nbsp;personal&nbsp;a&nbsp;través&nbsp;de&nbsp;su&nbsp;cuenta.
                            <br />
                            -&nbsp;&nbsp;Corrección:&nbsp;Puede&nbsp;solicitar&nbsp;la&nbsp;corrección&nbsp;de&nbsp;cualquier&nbsp;información&nbsp;personal&nbsp;incompleta&nbsp;o&nbsp;inexacta&nbsp;que&nbsp;tengamos&nbsp;sobre&nbsp;usted.
                            <br />
                            -&nbsp;&nbsp;Eliminación:&nbsp;Puede&nbsp;solicitar&nbsp;la&nbsp;eliminación&nbsp;de&nbsp;su&nbsp;información&nbsp;personal&nbsp;en&nbsp;los&nbsp;siguientes&nbsp;casos:
                            <br />
                            1.&nbsp;Si&nbsp;el&nbsp;procesamiento&nbsp;de&nbsp;la&nbsp;información&nbsp;personal&nbsp;infringe&nbsp;la&nbsp;legislación&nbsp;o&nbsp;esta&nbsp;política;
                            <br />
                            <br />
                            2.&nbsp;Si&nbsp;recopilamos&nbsp;y&nbsp;usamos&nbsp;su&nbsp;información&nbsp;sin&nbsp;su&nbsp;consentimiento,&nbsp;o&nbsp;si&nbsp;revoca&nbsp;su&nbsp;consentimiento;
                            <br />
                            <br />
                            3.Si&nbsp;el&nbsp;procesamiento&nbsp;de&nbsp;su&nbsp;información&nbsp;infringe&nbsp;acuerdos&nbsp;con&nbsp;usted;
                            <br />
                            <br />
                            4.Si&nbsp;ya&nbsp;no&nbsp;utiliza&nbsp;nuestros&nbsp;productos&nbsp;o&nbsp;servicios,&nbsp;o&nbsp;cancela&nbsp;su&nbsp;cuenta;
                            <br />
                            <br />
                            5.Si&nbsp;dejamos&nbsp;de&nbsp;ofrecer&nbsp;productos&nbsp;o&nbsp;servicios,&nbsp;o&nbsp;el&nbsp;período&nbsp;de&nbsp;retención&nbsp;ha&nbsp;expirado.
                            <br />
                            <br />
                            -&nbsp;&nbsp;Limitación&nbsp;de&nbsp;Decisiones&nbsp;Automatizadas:&nbsp;En&nbsp;algunas&nbsp;funciones&nbsp;comerciales,&nbsp;podemos&nbsp;ofrecer&nbsp;servicios&nbsp;personalizados&nbsp;basados&nbsp;en&nbsp;mecanismos&nbsp;de&nbsp;decisiones&nbsp;automatizadas&nbsp;(en&nbsp;lugar&nbsp;de&nbsp;decisiones&nbsp;humanas).&nbsp;Si&nbsp;tiene&nbsp;alguna&nbsp;duda,&nbsp;puede&nbsp;contactarnos.
                            <br />
                            -&nbsp;&nbsp;Objeción:&nbsp;Puede&nbsp;oponerse&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;al&nbsp;uso&nbsp;de&nbsp;su&nbsp;información&nbsp;personal&nbsp;para&nbsp;marketing&nbsp;directo.
                            <br />
                            -&nbsp;&nbsp;Cambio&nbsp;en&nbsp;el&nbsp;Alcance&nbsp;del&nbsp;Consentimiento:&nbsp;Tiene&nbsp;derecho&nbsp;a&nbsp;retirar&nbsp;su&nbsp;consentimiento&nbsp;en&nbsp;cualquier&nbsp;momento,&nbsp;sin&nbsp;afectar&nbsp;la&nbsp;legalidad&nbsp;del&nbsp;procesamiento&nbsp;basado&nbsp;en&nbsp;el&nbsp;consentimiento&nbsp;previo.
                            <br />
                            -&nbsp;&nbsp;Cancelación&nbsp;de&nbsp;Cuenta:&nbsp;Puede&nbsp;cancelar&nbsp;su&nbsp;cuenta.&nbsp;Una&nbsp;vez&nbsp;cancelada,&nbsp;dejaremos&nbsp;de&nbsp;ofrecerle&nbsp;productos&nbsp;y&nbsp;servicios,&nbsp;y&nbsp;eliminaremos&nbsp;o&nbsp;anonimizarremos&nbsp;su&nbsp;información&nbsp;personal&nbsp;según&nbsp;su&nbsp;solicitud,&nbsp;a&nbsp;menos&nbsp;que&nbsp;la&nbsp;ley&nbsp;indique&nbsp;lo&nbsp;contrario.
                            <br />
                            -&nbsp;&nbsp;Derecho&nbsp;de&nbsp;Explicación:&nbsp;Puede&nbsp;solicitar&nbsp;que&nbsp;le&nbsp;expliquemos&nbsp;esta&nbsp;política.&nbsp;Para&nbsp;cualquier&nbsp;duda,&nbsp;comuníquese&nbsp;con&nbsp;nosotros&nbsp;a&nbsp;través&nbsp;de&nbsp;los&nbsp;datos&nbsp;de&nbsp;contacto&nbsp;proporcionados&nbsp;en&nbsp;esta&nbsp;política
                        </span>
                    </div>
                    <img
                        class="image_4"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng24ed7da1cdb80b7601d80cc468fc1f7687169a85f0354b26e85dfdf8b9673e5b.png"
                    />
                </div>
                <div class="group_6 flex-row justify-between">
                    <img
                        class="image_5"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPngfa509971f6322bb0954403d68f6b74a9dd00cbfacbba608fcba6202357cc1b1f.png"
                    />
                    <div class="text-wrapper_5 flex-col">
                        <span class="text_17"
                            >Protección&nbsp;de&nbsp;Menores：</span
                        >
                        <span class="text_18">
                            Valoramos&nbsp;la&nbsp;protección&nbsp;de&nbsp;la&nbsp;información&nbsp;personal&nbsp;de&nbsp;los&nbsp;menores.&nbsp;Para&nbsp;los&nbsp;usuarios&nbsp;menores&nbsp;de&nbsp;edad&nbsp;(según&nbsp;las&nbsp;leyes&nbsp;de&nbsp;su&nbsp;país&nbsp;o&nbsp;región),&nbsp;tomaremos&nbsp;medidas&nbsp;adicionales&nbsp;para&nbsp;proteger&nbsp;la&nbsp;privacidad&nbsp;y&nbsp;seguridad&nbsp;de&nbsp;su&nbsp;información&nbsp;personal.&nbsp;Antes&nbsp;de&nbsp;recopilar&nbsp;información&nbsp;personal&nbsp;de&nbsp;menores,&nbsp;haremos&nbsp;todo&nbsp;lo&nbsp;posible&nbsp;para&nbsp;obtener&nbsp;el&nbsp;consentimiento&nbsp;explícito&nbsp;de&nbsp;su&nbsp;tutor&nbsp;legal.&nbsp;Si&nbsp;es&nbsp;menor&nbsp;de&nbsp;edad,&nbsp;por&nbsp;favor,&nbsp;obtenga&nbsp;el&nbsp;consentimiento&nbsp;de&nbsp;sus&nbsp;padres&nbsp;o&nbsp;tutor&nbsp;legal&nbsp;antes&nbsp;de&nbsp;usar&nbsp;esta&nbsp;aplicación&nbsp;y&nbsp;pídales&nbsp;que&nbsp;lean&nbsp;y&nbsp;comprendan&nbsp;esta&nbsp;política&nbsp;de&nbsp;privacidad.
                        </span>
                        <span class="text_19"
                            >Cambios&nbsp;en&nbsp;Esta&nbsp;Política：</span
                        >
                        <span class="paragraph_7">
                            Podemos&nbsp;actualizar&nbsp;esta&nbsp;política&nbsp;de&nbsp;privacidad&nbsp;ocasionalmente&nbsp;para&nbsp;reflejar&nbsp;cambios&nbsp;en&nbsp;la&nbsp;aplicación&nbsp;y&nbsp;actualizaciones&nbsp;en&nbsp;las&nbsp;leyes&nbsp;y&nbsp;regulaciones.&nbsp;En&nbsp;caso&nbsp;de&nbsp;cambios&nbsp;significativos,&nbsp;proporcionaremos&nbsp;una&nbsp;notificación&nbsp;destacada&nbsp;en&nbsp;la&nbsp;aplicación&nbsp;y,&nbsp;si&nbsp;es&nbsp;necesario,&nbsp;solicitaremos&nbsp;su&nbsp;consentimiento&nbsp;explícito.
                            <br />
                            Le&nbsp;recomendamos&nbsp;revisar&nbsp;esta&nbsp;política&nbsp;de&nbsp;privacidad&nbsp;periódicamente&nbsp;para&nbsp;entender&nbsp;cómo&nbsp;manejamos&nbsp;y&nbsp;protegemos&nbsp;su&nbsp;información&nbsp;personal.&nbsp;Si&nbsp;continúa&nbsp;utilizando&nbsp;la&nbsp;aplicación,&nbsp;acepta&nbsp;y&nbsp;acepta&nbsp;el&nbsp;contenido&nbsp;de&nbsp;esta&nbsp;política&nbsp;de&nbsp;privacidad.
                        </span>
                        <span class="text_20">Contáctenos：</span>
                        <span class="text_21">
                            Si&nbsp;tiene&nbsp;alguna&nbsp;pregunta&nbsp;o&nbsp;inquietud&nbsp;sobre&nbsp;esta&nbsp;política&nbsp;de&nbsp;privacidad,&nbsp;o&nbsp;desea&nbsp;consultar&nbsp;o&nbsp;solicitar&nbsp;cualquier&nbsp;asunto&nbsp;relacionado&nbsp;con&nbsp;el&nbsp;manejo&nbsp;de&nbsp;sus&nbsp;datos&nbsp;personales,&nbsp;puede&nbsp;contactarnos&nbsp;a&nbsp;través&nbsp;de&nbsp;servicio&#64;quantumlion.mx.
                        </span>
                    </div>
                </div>
                <div class="group_7 flex-col"></div>
            </div>
            <div class="group_8 flex-row">
                <div class="image-text_2 flex-row justify-between">
                    <div class="box_1 flex-col">
                        <div class="box_2 flex-col">
                            <div class="section_2 flex-col">
                                <div class="group_9 flex-col">
                                    <div class="group_10 flex-col"></div>
                                    <div class="text-wrapper_6 flex-col">
                                        <span class="text_22">$</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img
                            class="image_6"
                            referrerpolicy="no-referrer"
                            src="./assets/img/SketchPng00a9bb566a6a5640b88379b9ef17f51a0240bf27a6fa2357c366da5a45701173.png"
                        />
                        <div class="box_3 flex-col justify-center">
                            <div class="image-wrapper_1 flex-col">
                                <img
                                    class="thumbnail_1"
                                    referrerpolicy="no-referrer"
                                    src="./assets/img/SketchPngac7546aaa97af44ddcddcc4242738ecf71015fef0f14543c4eef7a47c76af2d9.png"
                                />
                                <img
                                    class="thumbnail_2"
                                    referrerpolicy="no-referrer"
                                    src="./assets/img/SketchPng80c196a5dbfb993bf22ce38d28b0132272d7ac37e97d7b3d7c5c2632692fdcf5.png"
                                />
                            </div>
                        </div>
                        <div class="box_4 flex-col">
                            <div class="group_11 flex-col"></div>
                        </div>
                        <div class="text-wrapper_7 flex-col">
                            <span class="text_23">LoanMXN</span>
                        </div>
                    </div>
                    <a
                        class="text-group_2"
                        :href="$constants.privateUrl"
                        style="cursor: pointer"
                        >《Politica&nbsp;de&nbsp;privacidad》</a
                    >
                </div>
                <img
                    class="image_7"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng91c3f620a0cd3607c89520da73ab9eefd23af87090aef8c53fd51373e7ed7c05.png"
                />
                <div class="text-wrapper_8">
                    <span class="text_24">Tel:</span>
                    <span class="text_25">+525584682138&nbsp;｜</span>
                    <span class="text_26">&nbsp;Correo:</span>
                    <span class="paragraph_8">
                        &nbsp;servicio&#64;quantumlion.mx
                        <br />
                    </span>
                    <span class="text_27"
                        >Domicilio:&nbsp;Torres&nbsp;Adalid&nbsp;No.&nbsp;618&nbsp;Del&nbsp;Valle&nbsp;Centro,&nbsp;Benito&nbsp;juárez&nbsp;méxico,&nbsp;CIUDAD&nbsp;DE&nbsp;méxico,&nbsp;03100&nbsp;méxico
                    </span>
                </div>
            </div>
        </div>
        <div class="group_12 flex-row">
            <div class="group_13 flex-col">
                <div class="box_5 flex-col">
                    <div class="group_14 flex-col">
                        <div class="group_15 flex-col">
                            <div class="box_6 flex-col"></div>
                            <div class="text-wrapper_9 flex-col">
                                <span class="text_29">$</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    class="image_8"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPngaea889466a20e50f5c7b14030e53c4c9f5c51c5bdd54361b10d3a315245fd1cd.png"
                />
                <div class="box_7 flex-col justify-center">
                    <div class="image-wrapper_2 flex-col">
                        <img
                            class="thumbnail_3"
                            referrerpolicy="no-referrer"
                            src="./assets/img/SketchPngb9e8a17fa057c37aec7b7b6b7706a89af3af2a3c760345da13a2d688bb2c6a11.png"
                        />
                    </div>
                </div>
                <div class="box_8 flex-col">
                    <div class="block_1 flex-col">
                        <div class="box_9 flex-col"></div>
                    </div>
                </div>
                <div class="text-wrapper_10 flex-col">
                    <span class="text_30">LoanMXN</span>
                </div>
            </div>
            <span class="text_31">Quantum&nbsp;Lion-LoanMXN</span>
            <span
                class="text_32"
                style="cursor: pointer"
                @click="tabJump('guanwangshouye')"
                >Sobre&nbsp;Nosotros</span
            >
            <span
                class="text_33"
                style="cursor: pointer"
                @click="tabJump('lianxiwomen')"
                >Contáctanos</span
            >
            <div class="group_16 flex-col justify-between">
                <span
                    class="text_34"
                    style="cursor: pointer"
                    @click="tabJump('yinsixieyi')"
                    >Política&nbsp;de&nbsp;Privacidad</span
                >
                <div class="block_2 flex-col"></div>
            </div>
            <span
                class="text_35"
                style="cursor: pointer"
                @click="tabJump('shanchuzhanghao')"
                >Eliminar&nbsp;Cuenta</span
            >
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            constants: {}
        }
    },
    methods: {
        tabJump(value) {
            this.$router.push({ path: value })
        }
    }
}
</script>
<style scoped lang="css" src="./assets/index.response.css" />