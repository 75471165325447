import Vue from 'vue'
import VueRouter from 'vue-router'
import yinsixieyi from '../views/yinsixieyi/index.vue'
import guanwangshouye from "../views/guanwangshouye/index.vue"
import guanwangshouyeyipingxuanfu from "../views/guanwangshouyeyipingxuanfu/index.vue"
import shanchuzhanghao from "../views/shanchuzhanghao/index.vue"
import shanchuzhanghaotishi from "../views/shanchuzhanghaotishi/index.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/guanwangshouye"
  },
  {
    path: '/lianxiwomen',
    name: "/lianxiwomen",
    component: guanwangshouye
  },
  {
    path: '/guanwangshouye',
    name: ' guanwangshouye',
    component: guanwangshouye
  },
  {
    path: '/guanwangshouyeyipingxuanfu',
    name: ' guanwangshouyeyipingxuanfu',
    component: guanwangshouyeyipingxuanfu
  },
  {
    path: '/yinsixieyi',
    name: ' yinsixieyi',
    component: yinsixieyi
  },
  {
    path: '/shanchuzhanghao',
    name: ' shanchuzhanghao',
    component: shanchuzhanghao
  }, {
    path: '/shanchuzhanghaotishi',
    name: ' shanchuzhanghaotishi',
    component: shanchuzhanghaotishi
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {  
//   console.log("to:",to);
  
//   if (to.path == '/lianxiwomen' && from.path == '/lianxiwomen') {  
//     // 这里不直接处理滚动，因为DOM可能还没渲染  
//     next(vm => {  
//       // vm.$el 现在是可用的，但更好的是等待mounted或nextTick  
//       vm.$nextTick(() => {  
//         // 滚动到指定位置
//         document.querySelector(".section_4").scrollIntoView();
//         return
//       });  
//     });  
//   } else {  
//     next();  
//   }  
// });  
 

export default router
