
import { Toast } from 'vant';
export default {
    showToast() {
        Toast({
            className: 'noticeWidth',   //所加样式名
            // position: 'top',
            duration: 1500,
            message: `  <div class="group_13 flex-row">
           <img
             class="thumbnail_2"
             referrerpolicy="no-referrer"
             src="./assets/img/SketchPngd28ae7fd36a738c6fcc64d581c6483b70e718f5f7818e42ecae5691d57356f41.png"
           />
           <span class="text_15">Eliminado&nbsp;con&nbsp;éxito&nbsp;¡esperamos&nbsp;verlo&nbsp;pronto!</span>
         </div>`,
            type: 'html',
        });
    }
}