<template>
  <div>
    <div id="main-content">
      <YiPinShouYe/>
    </div>
    <div ref="footerRef" class="footer">
      <elg-bottom/>
    </div>
  </div>
</template>
<script>
import YiPinShouYe from "./yinpinshouye.vue";
import ElgBottom from "./elg-bottom.vue";
export default {
    components: {ElgBottom,  YiPinShouYe},
  data() {
    return {
     content: [
        { id: 1, text: '这里是页面的其他内容，可以滚动。' },
        { id: 2, text: '这里是页面的其他内容，可以滚动。' },
        // 更多内容...
      ],
    };
  },
  mounted() {
    this.updateFooterStyle();
  },
  methods: {
    updateFooterStyle() {
      // const footerHeight = this.$refs.footerRef.offsetHeight;
      // this.$refs.footerRef.style.position = 'fixed';
      // this.$refs.footerRef.style.bottom = `-${footerHeight}px`; // 负数，以确保不覆盖内容
      // this.$refs.footerRef.style.width = '100%';
      // this.$refs.footerRef.style.zIndex = '1000'; // 设置较高的 z-index
    },
  },
};
</script>

 <style scoped lang="css" src="./assets/index.response.css" />