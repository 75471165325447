<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="box_1 flex-row">
        <div class="group_1 flex-col">
          <div class="group_2 flex-col">
            <div class="group_3 flex-col">
              <div class="box_2 flex-col">
                <div class="group_4 flex-col"></div>
                <div class="text-wrapper_1 flex-col"><span class="text_1">$</span></div>
              </div>
            </div>
          </div>
          <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngaea889466a20e50f5c7b14030e53c4c9f5c51c5bdd54361b10d3a315245fd1cd.png"
          />
          <div class="group_5 flex-col justify-center">
            <div class="image-wrapper_1 flex-col">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngb9e8a17fa057c37aec7b7b6b7706a89af3af2a3c760345da13a2d688bb2c6a11.png"
              />
            </div>
          </div>
          <div class="group_6 flex-col">
            <div class="group_7 flex-col"><div class="group_8 flex-col"></div></div>
          </div>
          <div class="text-wrapper_2 flex-col"><span class="text_2">LoanMXN</span></div>
        </div>
        <span class="text_3">Quantum&nbsp;Lion-LoanMXN</span>
        <span class="text_4">Sobre&nbsp;Nosotros</span>
        <span class="text_5">Contáctanos</span>
        <span class="text_6">Política&nbsp;de&nbsp;Privacidad</span>
        <div class="group_9 flex-col justify-between">
          <span class="text_7">Eliminar&nbsp;Cuenta</span>
          <div class="box_3 flex-col"></div>
        </div>
      </div>
      <div class="box_4 flex-col">
        <span class="text_8">CERRAR&nbsp;CUENTA</span>
        <span class="text_9">CERRAR&nbsp;CUENTA</span>
        <div class="image-text_1 flex-col justify-between">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngef47ad417fa5b93765b9f81e3e7c863e2fd102c9f4bc2d7f9cec21f72a0a5dc8.png"
          />
          <span class="text-group_1">
            ¿Por&nbsp;qué&nbsp;desea&nbsp;dejarnos?&nbsp;Si&nbsp;tiene&nbsp;algún&nbsp;problema,&nbsp;contáctenos&nbsp;y&nbsp;haremos&nbsp;todo&nbsp;lo&nbsp;posible&nbsp;para&nbsp;solucionarlo.&nbsp;¡Déjenos&nbsp;una&nbsp;oportunidad&nbsp;más!
          </span>
        </div>
        <div class="group_10 flex-col">
          <span class="text_10">
            Antes&nbsp;de&nbsp;cerrar&nbsp;la&nbsp;cuenta,&nbsp;por&nbsp;favor,&nbsp;lea&nbsp;atentamente&nbsp;las&nbsp;siguientes&nbsp;advertencias&nbsp;importantes.&nbsp;Después&nbsp;de&nbsp;cerrar&nbsp;la&nbsp;cuenta,&nbsp;no&nbsp;podrá&nbsp;utilizarla,&nbsp;incluyendo,&nbsp;pero&nbsp;no&nbsp;limitado&nbsp;a:
          </span>
          <div class="section_2 flex-row">
            <div class="section_3 flex-col">
              <div class="box_5 flex-col"></div>
              <div class="box_6 flex-col"></div>
              <div class="box_7 flex-col"></div>
            </div>
            <div class="text-wrapper_3">
              <span class="text_11">01</span>
              <span class="paragraph_1">
                &nbsp;&nbsp;No&nbsp;podrá&nbsp;iniciar&nbsp;sesión&nbsp;ni&nbsp;usar&nbsp;esta&nbsp;cuenta&nbsp;nuevamente.&nbsp;La&nbsp;imagen&nbsp;de&nbsp;perfil&nbsp;de&nbsp;la&nbsp;cuenta&nbsp;se&nbsp;restablecerá&nbsp;a&nbsp;la&nbsp;predeterminada&nbsp;y&nbsp;el&nbsp;apodo&nbsp;se&nbsp;restablecerá&nbsp;a&nbsp;"Usuario&nbsp;cerrado".
                <br />
                <br />
              </span>
              <span class="text_12">02</span>
              <span class="paragraph_2">
                &nbsp;&nbsp;La&nbsp;información&nbsp;personal&nbsp;y&nbsp;el&nbsp;historial&nbsp;de&nbsp;esta&nbsp;cuenta&nbsp;serán&nbsp;irreversiblemente&nbsp;eliminados.
                <br />
                <br />
              </span>
              <span class="text_13">03</span>
              <span class="text_14">
                &nbsp;&nbsp;La&nbsp;información&nbsp;de&nbsp;autenticación&nbsp;de&nbsp;esta&nbsp;cuenta&nbsp;será&nbsp;borrada.
              </span>
            </div>
          </div>
        </div>
        <div class="group_11 flex-col"></div>
        <div class="group_12 flex-col"></div>
        <div class="group_13 flex-row">
          <img
            class="thumbnail_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngd28ae7fd36a738c6fcc64d581c6483b70e718f5f7818e42ecae5691d57356f41.png"
          />
          <span class="text_15">Eliminado&nbsp;con&nbsp;éxito&nbsp;¡esperamos&nbsp;verlo&nbsp;pronto!</span>
        </div>
      </div>
      <div class="box_8 flex-row justify-between">
        <div class="text-wrapper_4 flex-col">
          <span class="text_16">Introduzca&nbsp;su&nbsp;número&nbsp;de&nbsp;teléfono&nbsp;móvil</span>
        </div>
        <div class="text-wrapper_5 flex-col">
          <span class="text_17">Confirmar&nbsp;cierre&nbsp;de&nbsp;cuenta</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />